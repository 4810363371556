import {
  SnapshotVehicleDispatchTypes,
  SNAPSHOT_VEHICLES_LOADING,
  SNAPSHOT_VEHICLES_FAILURE,
  SNAPSHOT_VEHICLES_SUCCESS,
  SnapshotVehicles,
} from "../actions/snapshotVehicleActionTypes";

interface DefaultStateInterface {
  loading: boolean;
  vehicles?: Array<SnapshotVehicles>;
  hasData?: boolean;
}

const defaultState: DefaultStateInterface = {
  loading: false,
  hasData: false,
};

const snapshotVehiclesReducer = (
  state: DefaultStateInterface = defaultState,
  action: SnapshotVehicleDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case SNAPSHOT_VEHICLES_FAILURE:
      return {
        loading: false,
      };
    case SNAPSHOT_VEHICLES_LOADING:
      return {
        loading: true,
      };
    case SNAPSHOT_VEHICLES_SUCCESS:
      return {
        loading: false,
        vehicles: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export type SnapshotVehicleRootState = ReturnType<
  typeof snapshotVehiclesReducer
>;

export default snapshotVehiclesReducer;
