export const SETTINGS_LOADING = "SETTINGS_LOADING";
export const SETTINGS_FAILURE = "SETTINGS_FAILURE";
export const SETTINGS_SUCCESS = "SETTINGS_SUCCESS";

export type SettingsType = {
  vehicles: Array<Vehicles>;
};

export type Vehicles = {
  DCM: string;
  AssetNumber: string;
  MCUFirmwareVersion: string;
  DCMFirmwareVersion: string;
};

export interface SettingsLoading {
  type: typeof SETTINGS_LOADING;
}

export interface SettingsFailure {
  type: typeof SETTINGS_FAILURE;
}

export interface SettingsSuccess {
  type: typeof SETTINGS_SUCCESS;
  payload: Array<Vehicles>;
}

export type SettingsDispatchTypes =
  | SettingsLoading
  | SettingsFailure
  | SettingsSuccess;
