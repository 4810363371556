export const SNAPSHOT_METRICS_LOADING = "SNAPSHOT_METRICS_LOADING";
export const SNAPSHOT_METRICS_SUCCESS = "SNAPSHOT_METRICS_SUCCESS";

export type SnapshotMetrics = {
  id: string;
  assetNumber: string;
  dates: string[];
  solarChargeCurrentGraph: (number | null)[];
  batteryCurrentGraph: (number | null)[];
  systemState: number;
  solarEnergyGraph: (number | null)[];
  totalSolarEnergy: number;
  totalSolarPower: number;
  totalHours: number;
  loadGraph: (number | null)[];
  totalLoad: number;
  maxSolarPower: number;
  maxPowerTimestamp: string;
  stateOfCharge: number;
  voltageGraph: (number | null)[];
  lastDate: string;
  temperatureGraph: (number | null)[];
  batteryVoltage: number;
  solarAmps: number;
  loadAmps: number;
  temperature: number;
  dailyLoadArr: {
    date: string;
    lastNumber: number;
  }[];
  dailySolarGenArr: {
    date: string;
    lastNumber: number;
  }[];
  SOCGraph: (number | null)[];
  SPSystemStatus: string;
  generatorStatus: string;
  SPActiveHours: number;
  generatorActiveHours: number;
  SPChargingTimes: String[];
  SPOnTimes: String[];
  generatorOnTimes: String[];
  SPFirstTurnOnTime: string;
  signalStrength: number;
  SPChargingStatus: string;
  shorelineChargingTimes: String[];
  shorelineChargingHours: number;
};

export interface SnapshotMetricsLoading {
  type: typeof SNAPSHOT_METRICS_LOADING;
}

export interface SnapshotMetricsSuccess {
  type: typeof SNAPSHOT_METRICS_SUCCESS;
  payload: Array<SnapshotMetrics>;
}

export type SnapshotMetricsDispatchTypes =
  | SnapshotMetricsLoading
  | SnapshotMetricsSuccess;
