import { ReactElement, ReactNode } from "react";
export const VEHICLES_LOADING = "VEHICLES_LOADING";
export const VEHICLES_FAILURE = "VEHICLES_FAILURE";
export const VEHICLES_SUCCESS = "VEHICLES_SUCCESS";

export type Vehicles = {
  id: string;
  AssetNumber: string;
  values: Array<VehicleData>;
  Location: string;
};

export type VehicleData = {
  Date: string;
  DeviceName: string;
  StealthBatteryAmps: number;
  BMSPackVolts: number;
  SolarAmps: number;
  GensetVolts: number;
  SystemState: number;
  SystemTemperature: number;
  DaylightSignal: boolean;
  RSSI: number;
  ShorelineConnected: boolean;
};

export interface VehicleLoading {
  type: typeof VEHICLES_LOADING;
}

export interface VehicleFailure {
  type: typeof VEHICLES_FAILURE;
}

export interface VehicleSuccess {
  type: typeof VEHICLES_SUCCESS;
  payload: Array<Vehicles>;
}

export type VehicleDispatchTypes =
  | VehicleLoading
  | VehicleFailure
  | VehicleSuccess;
