import {
  SnapshotMetricsDispatchTypes,
  SNAPSHOT_METRICS_LOADING,
  SNAPSHOT_METRICS_SUCCESS,
  SnapshotMetrics,
} from "../actions/snapshotMetricsActionTypes";

interface DefaultStateInterface {
  hasData?: boolean;
  metrics?: Array<SnapshotMetrics>;
  loading: boolean;
}

const defaultState: DefaultStateInterface = {
  hasData: false,
  loading: false,
};

const snapshotMetricsReducer = (
  state: DefaultStateInterface = defaultState,
  action: SnapshotMetricsDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case SNAPSHOT_METRICS_LOADING:
      return {
        loading: true,
      };
    case SNAPSHOT_METRICS_SUCCESS:
      return {
        loading: false,
        metrics: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export default snapshotMetricsReducer;
